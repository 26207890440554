import {Component} from '@angular/core';

@Component({
    template: `
        <div class="main-content">
            <div class="container-fluid">
                <router-outlet></router-outlet>
            </div>
        </div>
    `,
})
export class AgentComponent {
}
