
<div class="wrapper">
    <div class="sidebar" data-color="#4a638d" data-image="">
        <sidebar-cmp></sidebar-cmp>
    </div>
    <div class="main-panel">
        <navbar-cmp></navbar-cmp>
        <router-outlet></router-outlet>
          <footer-cmp></footer-cmp>
    </div>
</div>

