<div class="background-page">
    <div>
        <div class="text-center ">
            <h1 class="fw-bold clear-margin-h1">401</h1>
            <p class="fs-3">Opps! Unauthorized page.</p>
            <p class="lead">
                You don't have permission to access this page
            </p>
        </div>
    </div>
</div>
