import {Component, Input} from '@angular/core';
import {FilterData} from "../filter-object";
import {FilterService} from "../filter.service";
import * as moment from "moment";


@Component({
  selector: 'lib-filter-chips',
  templateUrl: './filter-chips.component.html'
})
export class FilterChipsComponent {

  @Input() removable = true;
  filterChips: FilterData[] = [];
  constructor(private filterService: FilterService) {
    this.filterService.chipsFilterEvent().subscribe(() => this.updateChips())
  }


  private updateChips() {
    this.filterChips = [];
    const filtersData = this.filterService.chipsData;

    for (const filterId in filtersData) {
      if (this.isValid(filtersData[filterId])) {
        this.filterChips.push(filtersData[filterId])
      }
    }
  }

  private isValid(filter: FilterData): boolean {
    if (filter === undefined || filter === null) {
      return false;
    }
    if (filter.value === undefined ||filter.value === null || filter.value === '') {
      return false;
    }

    if(moment.isMoment(filter.value) && (filter['filterID'] === 'begin' || filter['filterID'] === 'end')){
      filter.value = filter.value.format('MM-DD-YYYY');
    }

    return !(filter.ignoreValues instanceof Array && filter.ignoreValues.filter(entry => entry === filter.value).length > 0);

  }

  buttonClearClick(filter: FilterData): void {
    this.filterService.removeFilter(filter);
  }
}
