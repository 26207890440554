import {Component, EventEmitter, Inject, Input, Output} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";

@Component({
    selector: 'lib-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.scss']
})
export class ModalComponent  {

    @Input() title = '';
    @Input() message = '';
    @Input() okLabel = 'Ok';
    @Input() okType:'button' | 'link' | 'icon' = 'button';
    @Input() okIcon = '';
    @Input() okColor:'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'light' = 'primary';

    @Input() cancelLabel = 'Cancel';
    @Input() cancelType:'button' | 'link' | 'icon' = 'button';
    @Input() cancelIcon = '';
    @Input() cancelColor:'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'light' = 'warning';

    @Output() ok = new EventEmitter<any>();
    @Output() cancel = new EventEmitter<any>();

    constructor() {

    }


    okClick(): void {
        this.ok.emit();
    }

    cancelClick(): void {
        this.cancel.emit();
    }

}
