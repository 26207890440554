import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
    selector: 'lib-button',
    templateUrl: './button.component.html'
})
export class ButtonComponent {

    @Input() label = '';
    @Input() disabled = false;
    @Input() color: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'light' = 'primary';
    @Input() customClass = '';
    @Input() icon: string;
    @Input() iconSize: '24px' | '32px' | '48px' = '32px';
    @Input() buttonType: 'button' | 'link' | 'icon' = 'button';


    @Output() clickAction = new EventEmitter<any>();


    onClick($event: Event) {
        $event.stopPropagation();
        if (!this.disabled) {
            this.clickAction.emit($event);
        }
    }

    setDisableState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }
}
