<div class="background-page">
    <div>
        <div class="text-center ">
            <h1 class="fw-bold clear-margin-h1">404</h1>
            <p class="fs-3">Opps! Not found page.</p>
            <p class="lead">
                This page couldn't be found
            </p>
        </div>
    </div>
</div>
