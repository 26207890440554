import {map} from "rxjs";
import {SelectOptions} from "./inputs/field-select/select-options";
import * as moment from 'moment';

export function NOPE(input?: any): string {
    return '';
}

export function VOID(input?: any): void {

}

export function isBoolean(value: any): boolean {
    return typeof value === 'boolean'
}

export const functionConvertInSelectOptionsWithNameProperty = map<any, SelectOptions[]>(entity => entity.map(entity => new SelectOptions(entity, entity.name)));

export const functionConvertInSelectOptions = (propertyName: string) => map<any, SelectOptions[]>(entity => entity.map(entity => new SelectOptions(entity, entity[propertyName])));

export const isObject = (value): boolean => value !== null && typeof value === 'object';

export const isString = (value): boolean => typeof value === 'string';

export const isFunction = (value): boolean => typeof value === 'function';

export const isMoment = (value): boolean => moment.isMoment(value);
