<div class="logo">
    <a class="simple-text logo-normal ms-5" href="#">
        File Manager
    </a>
</div>

<div class="sidebar-wrapper">

    <ul class="nav">
        <li *ngFor="let menuitem of menuItems" routerLinkActive="active">
            <!--If is a single link-->
            <a *ngIf="menuitem.type === 'link'" [routerLink]="[menuitem.path]">
                <i class="{{menuitem.icontype}}"></i>
                <p>{{menuitem.title}}</p>
            </a>
            <!--If it have a submenu-->
            <a *ngIf="menuitem.type === 'sub'" data-toggle="collapse" href="#{{menuitem.title}}">
                <i class="{{menuitem.icontype}}"></i>
                <p>{{menuitem.title}}<b class="caret"></b></p>
            </a>

            <!--Display the submenu items-->
            <div *ngIf="menuitem.type === 'sub'" class="collapse" id="{{menuitem.title}}">
                <ul class="nav">
                    <li *ngFor="let childitem of menuitem.children" routerLinkActive="active">
                        <a *ngIf="menuitem.path && menuitem.path != '', else childrenPath"
                           [routerLink]="[menuitem.path, childitem.path]">
                            <span class="sidebar-mini">{{childitem.ab}}</span>
                            <span class="sidebar-normal">{{childitem.title}}</span>
                        </a>
                        <ng-template #childrenPath>
                            <a [routerLink]="[ childitem.path ]">
                                <span class="sidebar-mini">{{childitem.ab}}</span>
                                <span class="sidebar-normal">{{childitem.title}}</span>
                            </a>
                        </ng-template>
                    </li>
                </ul>
            </div>
        </li>
    </ul>

</div>
