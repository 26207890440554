import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {catchError, Observable, throwError} from "rxjs";
import {AuthService} from "./auth.service";
import {Router} from "@angular/router";
import {NotificationService} from "../../components/lib/notifications/notification.service";

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {

    constructor(
        private authService: AuthService,
        private router: Router,
        private notificationService: NotificationService
    ) {
        console.log('Guard Initialize', authService)
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.authService.isAuthenticated()) {
            const token = this.authService.accessDetail;
            const authReq = req.clone({
                setHeaders: {
                    Authorization: `${token.token_type} ${token.access_token}`
                }
            });

            return next
                .handle(authReq)
                .pipe(
                    catchError(error => this.handleError(error))
                )
        }
        return next.handle(req);
    }

    private handleError(error: HttpErrorResponse) {
        console.error(error);
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            return throwError(() => error.error)
        }

        if (error.status === 401 && error.error.path === 'redirect:/login') {
            this.router.navigateByUrl('/login');
            return throwError(() => new Error('Expired Session. Please do the login again'))
        }

        // The backend returned an unsuccessful response code.
        // The response body may contain clues as to what went wrong,
        // return an observable with a user-facing error message
        return throwError(() => error);
    }
}
