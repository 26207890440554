import {Directive} from '@angular/core';


@Directive({
    selector: '[libConstraintCheck]'
})
export class ConstraintCheckDirective {
    /*
        @Input() id = 'id';
        @Input() cids: string[] = [];

        private component: any;
        private property = '_data';

        constructor(private view: ViewContainerRef,
                    private service: ConstraintService) {
        }

        ngOnInit(): void {
            if (this.view[this.property].componentView.component) {
                this.component = this.view[this.property].componentView.component;
                this.service.constraintEvent().subscribe(state => this.updateState(state));
            }
        }

        updateState(state: boolean) {
            if (this.cids.length > 0) {
                state = this.service.hasCid(this.cids);
            }

            if (this.component.setDisabledState) {
                this.component.setDisabledState(!state);
            } else if (isBoolean(this.component.disabled)) {
                this.component.disabled = !state;
            }
        }*/
}
