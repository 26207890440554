import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanLoad,
    Data,
    Route,
    Router,
    RouterStateSnapshot,
    UrlSegment,
    UrlTree
} from "@angular/router";
import {Injectable} from "@angular/core";
import {AuthService} from "./auth.service";
import {AsyncSubject, Observable, of} from "rxjs";

@Injectable({
    providedIn: "root"
})
export class AuthGuard implements CanActivate, CanLoad {
    constructor(private router: Router, private auth: AuthService) {
    }

    canActivate(next: ActivatedRouteSnapshot,
                state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        const roles: Data[] = [];
        let route = state.root;
        while (route && route.children[0]) {
            route = route.children[0];
            roles.push(route.data);
        }
        if (this.auth.isReady) {
            return this.checkAuthentications(roles);
        }
        return of(roles).pipe(data => this.waitAuthServiceView(data));
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    canLoad(route: Route, segments: UrlSegment[]
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (this.auth.isReady) {
            return this.checkAuthentication(route.data);
        }
        return of(route.data).pipe(data => this.waitAuthServiceModule(data));

    }

    private waitAuthServiceModule(urlData: Observable<Data>): Observable<boolean> {
        const pipe = new AsyncSubject<boolean>();
        urlData.subscribe(
            data => this.auth.onReadyState().subscribe(
                () => {
                    pipe.next(this.checkAuthentication(data));
                    pipe.complete();
                }
            )
        );
        return pipe.asObservable();
    }

    private waitAuthServiceView(urlData: Observable<Data[]>): Observable<boolean | UrlTree> {
        const pipe = new AsyncSubject<boolean | UrlTree>();
        urlData.subscribe(
            data => {
                pipe.next(this.checkAuthentications(data));
                pipe.complete();
            }
        )
        return pipe.asObservable();
    }

    private checkAuthentications(roles: Data[]): boolean | UrlTree {
        if (!this.auth.isAuthenticated()) {
            return this.router.parseUrl('/login');
        }

        for (const role of roles) {
            if (!this.auth.validateAccess(role)) {
                console.log('Not Permitted', role);
                return this.router.parseUrl('/401');
            }
        }
        return true;
    }

    private checkAuthentication(role: Data): boolean {
        if (!this.auth.isAuthenticated()) {
            setTimeout(() => this.router.navigateByUrl('/login'), 100);
            return false;
        }
        return this.checkRoleAccess(role);
    }

    private checkRoleAccess(role: Data): boolean {
        if (!this.auth.validateAccess(role)) {
            console.log('Not Permitted', role);
            return false;
        }
        return true;
    }
}
