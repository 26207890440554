<div>
    <div class="row">
        <div class="col-md-12">
            <lib-filter-button></lib-filter-button>
            <div *ngIf="showFilter" [@fadeInOut]>

                <div>
                    <ng-content></ng-content>
                </div>
                <div class="float-end m-3">
                    <lib-button
                            *ngIf="buttonSearchLabel"
                            label="{{buttonSearchLabel}}"
                            buttonType="icon"
                            icon="fa fa-filter"
                            color="success"
                            (clickAction)="searchEmitter($event)"
                    ></lib-button>
                </div>
                <div class="float-end m-3">
                    <lib-button
                            *ngIf="buttonClearLabel"
                            label="{{buttonClearLabel}}"
                            buttonType="icon"
                            icon="fa fa-eraser"
                            color="warning"
                            (clickAction)="clearEmitter($event)"
                    ></lib-button>
                </div>
            </div>
        </div>
    </div>
</div>


