import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FilterService} from "../filter.service";
import {animate, style, transition, trigger} from "@angular/animations";

@Component({
    selector: 'lib-filter-area',
    templateUrl: './filter-area.component.html',
    animations: [
        trigger('fadeInOut', [
            transition(':enter', [
                style({opacity: 0}),
                animate(300, style({opacity: 1}))
            ]),
            transition(':leave', [
                animate(200, style({opacity: 0}))
            ])
        ])
    ]
})
export class FilterAreaComponent {
    @Input() title: string;
    @Input() buttonSearchLabel: string;
    @Input() buttonClearLabel: string;
    @Input() filter: any;
    @Output() clickClear =  new EventEmitter<any>();
    showFilter = false


    constructor(private filterService: FilterService) {
        filterService.openCloseFilterEvent().subscribe(() => {
            this.showFilter = !this.showFilter
        });
        filterService.dataFilterEvent().subscribe();
    }

    searchEmitter($event: Event) {
        $event.preventDefault();
        this.filterService.updateFilter(this.filter);
        this.showFilter = false;
    }

    clearEmitter($event: Event) {
        $event.stopPropagation();
        this.clickClear.emit($event);
    }
}
