<div class="wrapper wrapper-full-page">
    <div class="full-page login-page" data-color="green" data-image="#">

        <!--   you can change the color of the filter page using: data-color="blue | azure | green | orange | red | purple" -->
        <div class="content">
            <div class="container">
                <div class="row">
                    <div class="col-md-4 col-sm-6 col-md-offset-4 col-sm-offset-3">
                        <form (ngSubmit)="onSubmit()" #loginForm="ngForm">

                            <!--   if you want to have the card without animation please remove the ".card-hidden" class   -->
                            <div class="card">
                                <div class="header text-center">Login</div>
                                <div class="content">
                                    <lib-input-text label="Email address"
                                                    name="username"
                                                    type="email"
                                                    [(ngModel)]="user.userId"
                                                    placeholder="Enter email"
                                    ></lib-input-text>

                                    <lib-input-text label="Password"
                                                    name="password"
                                                    type="password"
                                                    [(ngModel)]="user.password"
                                                    placeholder="Password"
                                    ></lib-input-text>
                                    
                                </div>
                                <div class="footer text-center">
                                    <button type="submit" class="btn btn-fill btn-danger btn-wd">Login</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

        </div>

        <footer class="footer footer-transparent">
            <div class="container">
                <p class="copyright pull-right">
                    &copy; {{test | date: 'yyyy'}} Chargeback Resolve, an <a href="https://www.approcessing.com/">APPS
                    Inc.</a> offering
                </p>
            </div>
        </footer>

    </div>

</div>
