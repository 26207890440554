import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {finalize, Observable} from 'rxjs';
import {SpinnerService} from "./spinner.service";

@Injectable()
export class SpinnerInterceptor implements HttpInterceptor {

    private totalRequests = 0;

    constructor(private spinnerService: SpinnerService) {
    }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        this.totalRequests++;
        this.spinnerService.setLoading(true);
        return next.handle(request).pipe(finalize(() => {
            this.totalRequests--;
            if (this.totalRequests == 0) {
                this.spinnerService.setLoading(false);
            }
        }));
    }
}
