import {AbstractHttpService} from "./http-client.service";
import {Entity} from "./entity";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {Page, PageFilter} from "../pagination/page";

export abstract class BaseCrudService<K, E extends Entity<K> , F> extends AbstractHttpService {
    protected constructor(public uri: string, protected http: HttpClient) {
        super(uri, http);
    }

    public getAll(): Observable<Page<E>> {
        return this.http.get<Page<E>>(`${this.getBackendUrl()}${this.uri}`, this.options());
    }

    public getAllList(): Observable<E[]> {
        return this.http.get<E[]>(`${this.getBackendUrl()}${this.uri}/list`, this.options());
    }

    public get(id?: K) {
        let url = `${this.getBackendUrl()}${this.uri}`;

        if (id) {
            url += '/' + id;
        }
        return this.http.get<E>(url, this.options());
    }

    public post(entity: E): Observable<E> {
        return this.http.post<E>(this.getBackendUrl() + this.uri, entity, this.options());
    }

    public put(entity: E): Observable<E> {
        return this.http.put<E>(this.getBackendUrl() + this.uri + '/' + entity.id, entity, this.options());
    }

    public delete(id: K): Observable<E> {
        return this.http.delete<E>(this.getBackendUrl() + this.uri + '/' + id, this.options());
    }

    public filter(filter: PageFilter<F>): Observable<Page<E>> {
        return this.http.post<Page<E>>(this.getBackendUrl() + this.uri + '/filter', filter, this.options());
    }
}
