import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";

export abstract class AbstractHttpService {
    public queryParams: any = null;

    constructor(protected uri: string,
                protected http: HttpClient,
                private withCredentials: boolean = false
    ) {}

    protected abstract getBackendUrl(): string;


    protected options(
        options?: {
            headers?: HttpHeaders;
            observer?: 'body';
            params?: HttpParams;
            reportProgress?: boolean;
            responseType?: any;
            withCredentials?: boolean;
        }
    ) {
        let headers: HttpHeaders = new HttpHeaders();
        if (!options) {
            options = {headers};
        }

        if (options.headers) {
            for (const headerName of options.headers.keys()) {
                headers = headers.set(headerName, options.headers.getAll(headerName));
            }
        }

        if (this.queryParams) {
            options.params = new HttpParams({fromString: this.queryParams});
            this.queryParams = null;
        }

        options.headers = headers;

        if (!options.responseType) {
            options.responseType = 'json';
        }

        if (!options.withCredentials) {
            options.withCredentials = this.withCredentials;
        }
        return options;
    }

    protected logError(error: any) {
        if (error.status === 0) {
            console.log(`Backend unavailable [ ${this.getBackendUrl()} ]`);
        }
    }

}
