import {Injectable} from '@angular/core';

declare let $:any;
@Injectable({
    providedIn: 'root'
})
export class NotificationService {
    type = 'info'||'success'||'warning'||'danger';

    success(message: string) {
        this.showNotification('top', 'center', 'success', message)
    }

    info(message: string) {
        this.showNotification('top', 'center', 'info', message);
    }

    warning(message: string) {
        this.showNotification('top', 'center', 'warning', message);
    }

    error(message: string) {
        this.showNotification('top', 'center', 'danger', message);
    }

    private showNotification(from, align, type, message){
    	$.notify({
        	icon: "pe-7s-info",
        	message: message

        },{
            type: type,
            timer: 1000,
            placement: {
                from: from,
                align: align
            }
        });
	}
}
