<div class="main-content">
    <div class="container-fluid">
        <form #filterForm="ngForm">
            <lib-filter-view [filter]="filter"
                             [columns]="columns"
                             [service]="service"
                             add="false"
                             (clickClear)="clearFilter(filterForm)">


                <div class="card col-md-12">
                    <div class="header">Search Agent Log</div>
                    <div class="content">
                        <div class="row">

                            <div class="col-md-4">
                                <lib-field-select libFilter
                                                  [ignoreValues]="['All']"
                                                  label="Status"
                                                  [(ngModel)]="filter.status"
                                                  [options]="optionsStatus"
                                                  id="status"
                                                  name="status">
                                </lib-field-select>
                            </div>
                            <div class="col-md-4">
                                <lib-input-text libFilter
                                                label="File Name"
                                                [(ngModel)]="filter.fileName"
                                                id="fileName"
                                                name="fileName">
                                </lib-input-text>
                            </div>
                            <div class="col-md-4">
                                <lib-input-text libFilter
                                                label="Error"
                                                [(ngModel)]="filter.error"
                                                id="error"
                                                name="error">
                                </lib-input-text>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <lib-date-picker
                                        libFilter
                                        id="begin"
                                        label="Begin Date"
                                        [(ngModel)]="filter.begin"
                                        name="begin">
                                </lib-date-picker>
                            </div>
                            <div class="col-md-4">
                                <lib-date-picker libFilter
                                                 id="end"
                                                 label="End Date"
                                                 [(ngModel)]="filter.end"
                                                 name="end">
                                </lib-date-picker>
                            </div>

                        </div>
                    </div>
                </div>


            </lib-filter-view>

        </form>

    </div>
</div>