import {Component} from '@angular/core';
import {AgentLog} from "./agent-log.object";
import {TableColumn, textColumn} from "../../components/lib/table/table-objects";
import {AgentLogService} from "./agent-log.service";
import {FilterService} from "../../components/lib/filter/filter.service";
import {NgForm} from "@angular/forms";
import {BaseView} from "../../components/lib/base.view";
import {SelectOptions} from "../../components/lib/inputs/field-select/select-options";

@Component({
    selector: 'app-agent-log',
    templateUrl: './agent-log.component.html',
    providers: [FilterService]
})
export class AgentLogComponent extends BaseView {
    filter = new AgentLog();
    columns: TableColumn[] = [];
    optionsStatus: SelectOptions[] = [];
    sortable = true;


    constructor(protected service: AgentLogService) {
        super();
        this.columns = [
            textColumn('Status', 'status', this.sortable),
            textColumn('File Name', 'fileName', this.sortable),
            textColumn('Error', 'error', this.sortable),
            textColumn('Datetime', "datetime",this.sortable)
        ];

        this.optionsStatus = [
            new SelectOptions(null, 'All'),
            new SelectOptions('SUCCESS', 'Success'),
            new SelectOptions('ERROR', 'Error')
        ]


    }

    clearFilter(filterForm: NgForm) {
        filterForm.resetForm();
        this.filter = new AgentLog();
    }
}
