<h1 mat-dialog-title style="white-space: pre;">{{title}}</h1>

<div mat-dialog-content>
    <p style="white-space: pre;">{{message}}</p>
</div>

<div mat-dialog-actions cdkTrapFocus>
    <div style="margin-left: auto">
        <lib-button
         *ngIf="okLabel"
         [label]="okLabel"
         [buttonType]="okType"
         [icon]="okIcon"
         [color]="okColor"
         customClass="d-inline"
         (clickAction)="okClick()"
        >
        </lib-button>

        <lib-button
                *ngIf="cancelLabel"
                [label]="cancelLabel"
                [buttonType]="cancelType"
                [icon]="cancelIcon"
                [color]="cancelColor"
                customClass="d-inline"
                (clickAction)="cancelClick()"
        >

        </lib-button>
    </div>
</div>

