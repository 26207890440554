import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {TableBase} from "../table-base";
import {DomSanitizer} from "@angular/platform-browser";
import {TableColumn, TableColumnButton, TableColumnType, TableDataSource, TableFilterService} from "../table-objects";
import {Page, PageFilter} from "../../pagination/page";
import {FilterService} from "../../filter/filter.service";
import {isFunction, isObject, isString, NOPE, VOID} from "../../utils";

@Component({
    selector: 'lib-table-filter',
    templateUrl: './table-filter.component.html',
    encapsulation: ViewEncapsulation.None

})
export class TableFilterComponent extends TableBase<any> implements OnInit {

    @Input() columns: TableColumn[];
    @Input() data: TableFilterService<any, any>;
    @Input() tableName = '';
    @Input() hasPagination = true;
    @Input() addPath = 'add';
    @Input() buttonAdd = true

    @Output() pageLoad = new EventEmitter<Page<any>>();


    dataTable: Page<any> = new Page<any>();
    dataSource = new TableDataSource();
    loading = true;
    pageFilter = new PageFilter<any>();
    displayedColumns: string[];
    sortedColumn: TableColumn;

    constructor(protected sanitizer: DomSanitizer, protected filterService: FilterService) {
        super(sanitizer, []);
        this.filterService.dataFilterEvent().subscribe(data => {
            this.pageFilter.filter = data;
            this.gotoPage(0);
            this.loadElements();
        });
    }

    ngOnInit(): void {
        this.sortedColumn = this.columns.find(col => this.isSorted(col));
        this.filterService.setPage(this.pageSize, 0);
        this.displayedColumns = this.columns.map((c, i) => `col${i}`);
        this.columns.forEach(this.validateColumn)
        this.loadElements();
    }

    private validateColumn(column: TableColumn) {
        if (isObject(column.input)) {
            if (!isFunction(column.input.event)) {
                column.input.event = VOID;
            }
            if (column.type === TableColumnType.BUTTON) {
                if (!isFunction(column.input.disable)) {
                    column.input.disable = () => false;
                }

                if (!isFunction((column.input as TableColumnButton).css)) {
                    (column.input as TableColumnButton).css = NOPE;
                }

            }
        }

    }

    override updateRecord() {
        this.filterService.setPage(this.pageSize, this.pageNumber);
        this.loadElements();
    }

    private loadElements() {
        this.pageFilter.page = this.filterService.page;

        if(this.isSorted(this.sortedColumn)){
            this.pageFilter.page.sort = {
                field: this.sortedColumn.sort,
                order: this.sortedColumn.styleSort.substring(5) as 'asc' | 'desc'
            }
        }


        this.callLoadData();
    }

    callLoadData() {
        if (this.data) {
            this.data.lookupTableData(this.pageFilter).subscribe(data => {
                this.pageLoadEvent(data);
                this.dataSource.updateData(data.content);
                this.dataTable = data;
                this.pageLoad.next(data);
                this.loading = false;
            });
        }
    }

    private isSorted(column: TableColumn) {
        return column
            && isString(column.styleSort)
            && column.styleSort !== '';
    }

    sortable(column: TableColumn) {
        if (this.sortedColumn !== column) {
            if (this.sortedColumn) {
                this.sortedColumn.styleSort = '';
            }
            this.sortedColumn = column;
        }

        if(column.sort == ''){
            return;
        }

        switch (column.styleSort) {
            case 'sort-asc':
                column.styleSort = 'sort-desc';
                break;
            case 'sort-desc':
                column.styleSort = '';
                break;
            default:
                column.styleSort = 'sort-asc';
                break;
        }
        this.loadElements();
    }

    selectIcon(column: TableColumn) {
        if(column.styleSort === 'sort-asc'){
            return 'filtertable__th_icon fa fa-arrow-up';
        }

        if(column.styleSort === 'sort-desc'){
            return 'filtertable__th_icon fa fa-arrow-down';
        }
        return '';
    }
}

