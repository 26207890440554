import {isBoolean, isFunction, isString, NOPE, VOID} from "../utils";
import {MaskTypesEnum, NumericMask} from "../inputs/input-mask/input-mask-types";
import {SelectOptions} from "../inputs/field-select/select-options";
import {BehaviorSubject, Observable} from "rxjs";
import {DataSource} from "@angular/cdk/collections";
import {Page, PageFilter} from "../pagination/page";
import {Strings} from "../utils/strings";

export enum TableColumnType {
    TEXT,
    ICON,
    ICON_TEXT,
    INPUT,
    BUTTON
}

export class TableColumnInput {
    type?: 'TEXT' | 'TOGGLE' | 'CHECKBOX' | 'DATE' | 'DATE-RANGE' | 'SELECT' | 'AUTOCOMPLETE' = 'TEXT';
    icon?: string;
    mask?: MaskTypesEnum;
    color?: string;
    options?: SelectOptions[];
    numericMask?: NumericMask;
    disable?: boolean;
    model?: string;
    event?: (row: any, selected?: boolean) => void = VOID;
}

export class TableColumn {
    header: string;
    headerClass: string;
    headerStyle: string;
    sort: string;
    type: TableColumnType;
    input: TableColumnInput | TableColumnButton;
    event: (row: any, selected?: boolean) => void;
    columnDef: (row: any, type?: string) => string;
    styleClass: (row: any) => string;
    styleCss: (row: any) => string;
    styleSort?: string;
    title?: (row: any) => string;
    titleClass?: (row: any) => string;


    constructor(header: string,
                headerClass: string,
                headerStyle: string,
                sort: string,
                type: TableColumnType,
                input: TableColumnInput | TableColumnButton,
                event: (row: any, selected?: boolean) => void,
                columnDef: (row: any, type?: string) => string,
                styleClass: (row: any) => string,
                styleCss: (row: any) => string,
                title: (row: any) => string,
                titleClass: (row: any) => string) {
        this.header = header;
        this.headerClass = headerClass;
        this.headerStyle = headerStyle;
        this.sort = sort;
        this.type = type;
        this.input = input;
        this.event = event;
        this.columnDef = columnDef;
        this.styleClass = styleClass;
        this.styleCss = styleCss;
        this.title = title;
        this.titleClass = titleClass;
    }
}

export class TableColumnButton {
    type?: 'ICON+TEXT' | 'ICON' | 'TEXT' | 'ICON+MENU' | 'ICON+TEXT+INPUT' = 'ICON+TEXT';
    css?: (row: any) => string;
    label?: string;
    icon?: string;
    disable?: (row: any) => boolean;
    event?: (row: any) => void = VOID;


}

export interface TableService<F, R> {
    lookupTableData(parameter?: string, filter?: PageFilter<F>): Observable<Page<R>>;
}

export interface TableFilterService<F, R>{
    lookupTableData(filter?: PageFilter<F>): Observable<Page<R>>;
}

export class TableDataSource extends DataSource<any> {
    private dataEvent: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

    connect(): Observable<any[]> {
        return this.dataEvent.asObservable();
    }

    disconnect() {
    }

    updateData(data: any[]) {
        this.dataEvent.next(data);
    }
}

function checkFunction(fnc: (row: any) => void) {
    if (isFunction(fnc)) {
        return fnc;
    }
    return VOID;
}

function getFunction(parameter: string | ((row: any) => string)) {
    if (isString(parameter)) {
        const parameterAsString = (parameter as string);
        if (parameterAsString.includes('.')) {
            return (row: any) => {
                let object = row;
                const splitParameter = parameterAsString.split('.');
                for (const param of splitParameter) {
                    object = object[param];
                }
                return object as string;
            };
        }
        return (row: any) => row[parameterAsString];
    }

    if (isFunction(parameter)) {
        return parameter as ((row: any) => string);
    }
    return NOPE;
}

function getSort(columnDef: string | ((row: any) => string), sortable: string | boolean) {
    if (isString(columnDef)) {
        if (sortable === '' || sortable === true) {
            return columnDef as string;
        }
    }

    if (isBoolean(sortable) || !sortable) {
        return '';
    }

    return sortable as string;
}

export function textColumn(
    header: string,
    column: string | ((row: any) => string),
    sort: string | boolean,
    event: (row: any, selected?: boolean) => void = VOID,
    headerClass = '',
    headerStyle = '',
    styleClass: string | ((row: any) => string) = NOPE,
    styleCss: string | ((row: any) => string) = NOPE,
    title: string | ((row: any) => string) = NOPE,
    titleClass: string | ((row: any) => string) = NOPE
): TableColumn {
    return new TableColumn(
        header,
        headerClass,
        headerStyle,
        getSort(column, sort),
        TableColumnType.TEXT,
        undefined,
        checkFunction(event),
        getFunction(column),
        getFunction(styleClass),
        getFunction(styleCss),
        getFunction(title),
        getFunction(titleClass)
    );
}

export function iconColumn(
    header: string,
    icon: (row: any) => string,
    sort: string,
    event: (row: any) => void = VOID,
    headerClass = '',
    headerStyle = '',
    styleClass: string | ((row: any) => string) = NOPE,
    styleCss: string | ((row: any) => string) = NOPE,
    title: string | ((row: any) => string) = NOPE,
    titleClass: string | ((row: any) => string) = NOPE
): TableColumn {
    return new TableColumn(
        header,
        headerClass,
        headerStyle,
        sort,
        TableColumnType.ICON,
        undefined,
        checkFunction(event),
        getFunction(icon),
        getFunction(styleClass),
        getFunction(styleCss),
        getFunction(title),
        getFunction(titleClass)
    );
}

export function updateIcon(
    event: (row: any) => void = VOID,
    header = Strings.EMPTY
): TableColumn {
    return iconColumn(header,
        () => 'pe-7s-note',
        Strings.EMPTY,
        event,
        Strings.EMPTY,
        'width: 42px;',
        () => 'default-icon')
}

export function deleteIcon(
    event: (row: any) => void = VOID,
    header = Strings.EMPTY
): TableColumn{
    return iconColumn(header,
        () => 'pe-7s-trash',
        Strings.EMPTY,
        event,
        Strings.EMPTY,
        'width: 42px;',
        () => 'default-icon')
}




