import {Component, EventEmitter, forwardRef, Input, Output} from '@angular/core';
import {NG_VALUE_ACCESSOR} from "@angular/forms";
import {CustomValueAccessor} from "../../custom-value-accessor";
import * as moment from "moment";
import {Moment} from "moment";
import {isString} from "../../utils";


@Component({
    selector: 'lib-date-picker',
    templateUrl: './date-picker.component.html',
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => DatePickerComponent),
        multi: true
    }
    ]
})
export class DatePickerComponent extends CustomValueAccessor<Moment> {

    type = 'date';
    @Input() maxLength = 32000;
    @Input() required = false;
    @Input() readonly = false;
    @Input() placeholder = '';

    @Output() eventInput = new EventEmitter<any>();
    @Output() eventBlur = new EventEmitter<void>();

    private readonly OFFSET = 0;

    onInit(): void {
    }

    onInput() {
        const momentModel = this.convertStringInMoment(this.model);
        this.inputEvent(momentModel);
        this.eventInput.emit(momentModel);

    }

    translateValue(value: any): Moment {
        return value;
    }

    onBlur() {
        this.blurEvent();
        this.eventBlur.emit();
    }

    private convertStringInMoment(value: any) {
        if(isString(value) && value.length > 0){
            if (isString(value)) {
                return this.normalizeOffset(moment(value.substring(0, 10), 'YYYY-MM-DD'));
            }
        }
        return value;
    }

    private normalizeOffset(value: moment.Moment): moment.Moment {
        return value.utcOffset(this.OFFSET).startOf('day');
    }

}
