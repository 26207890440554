import {Directive, Input, OnDestroy, OnInit, ViewContainerRef} from "@angular/core";
import {Subscription} from "rxjs";
import {FilterService} from "./filter.service";
import {FilterComponent} from "./filter-object";

@Directive({
    selector: '[libFilter]',

})
export class FilterDirective implements OnInit, OnDestroy {
    @Input() id = 'id';
    @Input() ignoreValues: any[] = [];
    @Input() defaultValue: any;

    private filter: FilterComponent;
    private property = '_lContainer';
    private subscribe: Subscription;

    constructor(private view: ViewContainerRef,
                private filterService: FilterService) {
    }

    ngOnInit() {
        let component = this.view[this.property][0][8];

        if (component) {
           this.filter = component;

            if (this.filter.filterChange) {
                this.subscribe = this.filter.filterChange().subscribe(
                    value => {
                        const filter = {
                            ...value,
                            filterID: this.id,
                            ignoreValues: this.ignoreValues,
                            defaultValue: this.defaultValue
                        };
                        return this.filterService.publishFilter(filter);
                    }
                );
            }
        }
    }

    ngOnDestroy() {
        this.filter = null;
        this.subscribe.unsubscribe();
        this.filterService.unloadFilter(this.id);
    }

}