
<footer class="footer">
    <div class="container-fluid">
        <nav class="pull-left">

        </nav>
        <p class="copyright pull-right">
            &copy; {{test | date: 'yyyy'}} Chargeback Resolve, an <a href="https://www.approcessing.com/" target="_blank">APPS Inc.</a> offering
        </p>
    </div>
</footer>
