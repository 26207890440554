import {Component, EventEmitter, forwardRef, Input, Output} from '@angular/core';
import {CustomValueAccessor} from "../../custom-value-accessor";
import {NG_VALUE_ACCESSOR} from "@angular/forms";

@Component({
    selector: 'lib-input-text',
    templateUrl: './input-text.component.html',
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => InputTextComponent),
        multi: true
    }]
})
export class InputTextComponent extends CustomValueAccessor<string> {
    @Input() type: 'text' | 'password' | 'email' | 'number' = 'text';
    @Input() maxLength = 32000;
    @Input() required = false;
    @Input() readonly = false;
    @Input() textCase: 'normal' | 'lower' | 'upper' = 'normal';
    @Input() placeholder = '';

    @Output() eventInput = new EventEmitter<any>();
    @Output() eventBlur = new EventEmitter<void>();

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onInit(): void {
        if(this.label && !this.placeholder) {
            this.placeholder = this.label.toUpperCase();
        }
    }

    onInput() {
        this.setModelToLowerOrUpperCase(this.model, this.textCase);
        this.inputEvent(this.model);
        this.eventInput.emit(this.model);

    }

    translateValue(value: any): string {
        return value;
    }

    onBlur() {
        this.blurEvent();
        this.eventBlur.emit();
    }

    private setModelToLowerOrUpperCase(model: string, textCase: string) {
        switch (textCase) {
            case 'lower':
                this.model = model.toLowerCase();
                break;
            case 'upper':
                this.model = model.toUpperCase();
                break;
        }
    }
}
