import {Component, Input, ViewEncapsulation} from '@angular/core';
import {TableColumn, TableColumnType} from "../table-objects";

@Component({
    selector: 'lib-table-body',
    templateUrl: './table-body.component.html',
    encapsulation: ViewEncapsulation.None
})
export class TableBodyComponent {
    @Input() row: any;
    @Input() column: TableColumn;
    columnType = TableColumnType;
    

}
