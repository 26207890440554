import {Pipe, PipeTransform} from "@angular/core";

@Pipe({ name: 'orderBy' })
export class OrderByPipe implements PipeTransform {
    transform(array, orderBy, asc = true) {
        try {
            if (array) {
                if (!orderBy || orderBy.trim() === '') return array;

                if (asc) {
                    return Array.from(array).sort((item1: any, item2: any) => {
                        return this.orderByComparator(item1[orderBy], item2[orderBy]);
                    });
                } else {
                    return Array.from(array).sort((item1: any, item2: any) => {
                        return this.orderByComparator(item2[orderBy], item1[orderBy]);
                    });
                }

            }
        } catch (e){
            console.log('Error while trying to order.', e);
        }
    }

    private orderByComparator(item1: any, item2: any) {
        if (item1 === null && item2 === null) return 0;
        if (item1 === null) return -1; // nulls first
        if (item2 === null) return 1; // nulls first

        if ((isNaN(parseFloat(item1)) || !isFinite(item1) || (isNaN(parseFloat(item2)) || !isFinite(item2)))) {
            if (item1.toLowerCase() < item2.toLowerCase()) return -1;
            if (item1.toLowerCase() > item2.toLowerCase()) return 1;
        } else {
            if (parseFloat(item1) < parseFloat(item2)) return -1;
            if (parseFloat(item1) > parseFloat(item2)) return 1;
        }
        return 0;
    }
}