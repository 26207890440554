import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {LoginRoutingModule} from "./login.routing.module";
import {FormsModule} from "@angular/forms";
import {LoginComponent} from "./login.component";
import {ComponentModule} from "../../components/lib/component.module";

@NgModule({
    imports: [
        CommonModule,
        LoginRoutingModule,
        FormsModule,
        ComponentModule
    ],
    declarations: [
        LoginComponent
    ]
})
export class LoginModule {

}
