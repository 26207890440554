
<nav #navbar class="navbar navbar-default">
    <div class="container-fluid">
        <div class="navbar-minimize">
            <button id="minimizeSidebar" class="btn btn-danger btn-fill btn-round btn-icon">
                <i class="fa fa-ellipsis-v visible-on-sidebar-regular"></i>
                <i class="fa fa-navicon visible-on-sidebar-mini"></i>
            </button>
        </div>
        <div class="navbar-header">
            <button type="button" class="navbar-toggle" data-toggle="collapse" (click)="sidebarToggle()">
                <span class="sr-only">Toggle navigation</span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
            </button>
            <a class="navbar-brand">{{getTitle()}}</a>
        </div>
        <div class="collapse navbar-collapse">
            <div class="" *ngIf="isMobileMenu()">
<!--                <form class="navbar-form navbar-left navbar-search-form" role="search">-->
<!--                    <div class="input-group">-->
<!--                        <span class="input-group-addon"><i class="fa fa-search"></i></span>-->
<!--                        <input type="text" value="" class="form-control" placeholder="Search...">-->
<!--                    </div>-->
<!--                </form>-->


                <ul class="nav navbar-nav navbar-right">
                    <li class="dropdown dropdown-with-icons">
                        <a href="#" class="dropdown-toggle" data-toggle="dropdown">
                            <i class="fa fa-list"></i>
                            <p class="hidden-md hidden-lg">
                                More
                                <b class="caret"></b>
                            </p>
                        </a>
                        <ul class="dropdown-menu dropdown-with-icons">
                            <li><a (click)="logout()">
                                <i class="pe-7s-close-circle"></i>
                                Log out
                            </a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>

        </div>
    </div>
</nav>
