import {FormGroup} from "@angular/forms";

export function allOrNothingValidatorForInstructionSet(group: FormGroup): { [key: string]: any } | null {
    const controls = {...group.controls};
    // delete the controls that are not part of the validation
    delete controls['id'];
    delete controls['priority'];
    delete controls['active'];
    delete controls['exitOnFail'];

    if(controls['instructionType']?.value == 'MOVE' || controls['instructionType']?.value == 'COPY'){
        delete controls['parameter3'];
    }

    const filledFields = Object.keys(controls).filter(k => controls[k].value);
    if (filledFields.length === 0 || filledFields.length === Object.keys(controls).length) {
        return null;
    } else {
        return {'allOrNothing': {value: 'All fields should be filled out or none.'}};
    }
}


export function validateInputTextForParametersField(group: FormGroup): { [key: string]: any } | null {
    const validateDupleInput= new RegExp(/^(\{\d+:\d+\}|\d+:\d+)$/);
    const validateDupleInputSize= new RegExp(/^(\{\d+[MmGgKk]?:\d+[MmGgKk]?\}|\d+[MmGgKk]?:\d+[MmGgKk]?)$/);
    const validateOneInputSize = new RegExp(/^(\{\d+[MmGgKk]?}|\d+[MmGgKk]?)$/);
    const validateOneInput = new RegExp(/^(\{\d+}|\d+)$/);
    const validateTransform  = new RegExp(/^(\{[\w# $&]+:\d+\}|[\w# $&]+:\d+\])$/);



    function isValidTransform() {
        return group.controls['instructionType']?.value == 'TRANSFORM' && group.controls['parameter2'] && group.controls['parameter3']?.value;
    }


    function isValidOperation() {
        return (group.controls['instructionType']?.value != 'MOVE' || group.controls['instructionType']?.value != 'COPY') && group.controls['parameter3']?.value;
    }

    if (isValidTransform()) {
        if (!validateTransform.test(group.controls['parameter2']?.value)) {
            return {'validateInputTextForParametersField': {value: 'The parameter 2 should be in the format {x:y} or x:y .'}};
        }
    } else if (isValidOperation()) {
        switch (group.controls['parameter3']?.value) {
            case 'EQUALS':
            case 'NOT_EQUALS':
            case 'LESS_THAN':
            case 'GREATER_THAN':
            case 'LESS_THAN_OR_EQUALS':
            case 'GREATER_THAN_OR_EQUALS':
                if (group.controls['instructionType']?.value !== 'SIZE') {
                    if (!validateOneInput.test(group.controls['parameter2']?.value)) {
                        return {'validateInputTextForParametersField': {value: 'The parameter 2 should be in the format {x} or x.'}};
                    }
                } else {
                    if (!validateOneInputSize.test(group.controls['parameter2']?.value)) {
                        return {'validateInputTextForParametersField': {value: 'The parameter 2 should be in the format {x} or x.'}};
                    }
                }
                break;
            case 'IN_BETWEEN_INCLUSIVE':
            case 'IN_BETWEEN_EXCLUSIVE':
                if (group.controls['instructionType']?.value !== 'SIZE') {
                    if (!validateDupleInput.test(group.controls['parameter2']?.value)) {
                        return {'validateInputTextForParametersField': {value: 'The parameter 2 should be in the format {x:y} or x:y.'}};
                    }
                } else {
                    if (!validateDupleInputSize.test(group.controls['parameter2']?.value)) {
                        return {'validateInputTextForParametersField': {value: 'The parameter 2 should be in the format {x:y} or x:y.'}};
                    }
                }
                break;
        }
        return null;
    }
    return null;
}