<div class="float-start mb-3">
    <lib-button
    label="Advance Search"
    buttonType="icon"
    color="warning"
    icon="fa fa-search"
    (clickAction)="onClick()">

    </lib-button>
</div>
