import {Component} from '@angular/core';
import {RouteInfo} from "./sidebar.object";

declare let $: any;


//Menu Items
export const ROUTES: RouteInfo[] = [
    {
        path: '/alerts',
        title: 'Alert',
        type: 'link',
        icontype: 'pe-7s-alarm'
    },
    {
        path: '/fileLog',
        title: 'File Log',
        type: 'link',
        icontype: 'pe-7s-copy-file'
    },
    {
        path: '/file-type',
        title: 'File Type',
        type: 'link',
        icontype: 'pe-7s-edit'
    },
    {
        path: '/agent',
        title: 'Agent',
        type: 'link',
        icontype: 'pe-7s-config'
    },
    {
        path: '/agent-log',
        title: 'Agent Log',
        type: 'link',
        icontype: 'pe-7s-copy-file'
    },
    {
        path: '/site',
        title: 'Site',
        type: 'link',
        icontype: 'pe-7s-global'
    }


];

@Component({
    moduleId: module.id,
    selector: 'sidebar-cmp',
    templateUrl: 'sidebar.component.html',
})

export class SidebarComponent {
    public menuItems: any[];

    isNotMobileMenu() {
        return $(window).width() <= 991;

    }

    ngOnInit() {
        let isWindows = navigator.platform.indexOf('Win') > -1 ? true : false;
        this.menuItems = ROUTES.filter(menuItem => menuItem);

        isWindows = navigator.platform.indexOf('Win') > -1;

        if (isWindows) {
            // if we are on windows OS we activate the perfectScrollbar function
            $('.sidebar .sidebar-wrapper, .main-panel').perfectScrollbar();
            $('html').addClass('perfect-scrollbar-on');
        } else {
            $('html').addClass('perfect-scrollbar-off');
        }
    }

    ngAfterViewInit() {
        const $sidebarParent = $('.sidebar .nav > li.active .collapse li.active > a').parent().parent().parent();

        const collapseId = $sidebarParent.siblings('a').attr("href");

        $(collapseId).collapse("show");
    }
}
