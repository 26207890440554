import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BaseView} from "../../base.view";
import {TableColumn, TableFilterService} from "../../table/table-objects";
import {DEFAULT_PAGE_SIZE, DEFAULT_PAGE_SIZE_OPTIONS} from "../../pagination/page";
import {FilterService} from "../filter.service";

@Component({
    selector: 'lib-filter-view',
    templateUrl: './filter-view.component.html'
})
export class FilterViewComponent extends BaseView implements OnInit {

    @Input() titles: string[] = [];
    @Input() add = true;
    @Input() labelSearch = 'Search';
    @Input() labelClear = 'Clear';
    @Input() filter: any = {};
    @Input() columns: TableColumn[];
    @Input() service: TableFilterService<any, any>;
    @Input() pageSize = DEFAULT_PAGE_SIZE;
    @Input() pageSizeOptions = DEFAULT_PAGE_SIZE_OPTIONS;
    @Input() addPath = 'add';
    @Output() clickClear = new EventEmitter<any>();

    constructor(private filterService: FilterService) {
        super();
    }

    ngOnInit(): void {
        this.filterService.updateFilter(this.filter);
    }

    clickClearEvent($event: Event) {
        $event.stopPropagation();
        if (this.clickClear === null) {
            return;
        }
        this.clickClear.emit($event);
        this.filterService.clearFilter();
    }
}
