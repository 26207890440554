<div class="form-group">
    <label>{{label}}<span *ngIf="required" class="star">*</span></label>
    <input class="form-control"
           [(ngModel)]="model"
           (ngModelChange)="onInput()"
           [disabled]="disabled"
           [readOnly]="readonly"
           [required]="required"
           [type]="type"
           [maxlength]="maxLength"
           [placeholder]="placeholder"
           (blur)="onBlur()"
    >
    <div>{{constraintMessage}}</div>
</div>

