<div [class]="customClass" [ngSwitch]="buttonType">
    <button *ngSwitchCase="'button'" [class]="'btn btn-' + color" (click)="onClick($event)"
            [disabled]="disabled">{{ label }}
    </button>

    <button *ngSwitchCase="'link'" class="btn btn-link" (click)="onClick($event)" [disabled]="disabled">
        {{ label }}
    </button>

    <button *ngSwitchCase="'icon'" [class]="'btn btn-wd btn-' + color" (click)="onClick($event)">
        <span class="btn-label">
            <i [class]="icon"></i>
        </span>
        {{ label }}
    </button>
</div>
