import {Component, HostListener} from '@angular/core';
import {FormProvider} from "../../components/form-provider";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {allOrNothingValidatorForInstructionSet, validateInputTextForParametersField} from "./custom-validator";

@Component({
    selector: 'app-multi-step-form-agent',
    templateUrl: './multi-step-form-agent.component.html',
    providers: [{provide: FormProvider, useExisting: MultiStepFormAgentComponent}]
})
export class MultiStepFormAgentComponent extends FormProvider {
    private formMultiStep : FormGroup;
    private formMultiStepInitialized : any;

    readonly cronRegex = new RegExp(
        '(@(annually|yearly|monthly|weekly|daily|hourly|reboot))' +
        '|(@every (\\d+(ns|us|µs|ms|s|m|h))+)' +
        '|((((\\d+[#,/-])+\\d+|(\\d+|\\*([/-])\\d+)|' +
        '((JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC|MON|TUE|WED|THU|FRI|SAT|SUN|L)[ ,-])|' +
        '\\d+|\\*|\\?) ?){6,7})'
    );
    constructor(private fb: FormBuilder) {
        super();
       this.formMultiStep = this.fb.group({
            agentForm: this.fb.group({
                id: [null],
                path: [null, Validators.required],
                name: [null, Validators.required],
                description: [null, Validators.required],
                uuid: [null],
                instructionSets: [[]]
            }),
            instructionSetForm: this.fb.group({
                id: [null],
                name: [null, [Validators.required]],
                cron: [null, [Validators.required, Validators.pattern(this.cronRegex)]],
                newInstruction: this.fb.group({
                    id: [null],
                    description: [null],
                    parameter1: [null],
                    parameter2: [null],
                    parameter3: [{value: null, disabled: true}],
                    instructionType: [null],
                    priority: [0],
                    active: [true],
                    exitOnFail: [false],
                    alertLevel: [null]
                }, {validators: [allOrNothingValidatorForInstructionSet, validateInputTextForParametersField]}),
                instructions: this.fb.array([])
            })
        });

        this.formMultiStepInitialized = {... this.formMultiStep.value};
    }

    /**
     * HostListener for the window:beforeunload event.
     * Asks the user for confirmation before leaving the page.
     *
     * @param {Event} $event - The event object.
     */
   @HostListener('window:beforeunload', ['$event']) unloadNotification($event: Event) {
        const result = confirm('Are you sure you want to leave?');
        if(!result){
            $event.preventDefault();
        }
    }

    getForm(): FormGroup {
        return this.formMultiStep;
    }

    resetMultiForm(): void {
       this.formMultiStep.reset(this.formMultiStepInitialized);
    }

    resetNewInstruction(): void {
        this.formMultiStep.get('instructionSetForm.newInstruction')?.reset(this.formMultiStepInitialized?.instructionSetForm?.newInstruction);
    }
}
