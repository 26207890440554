import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {TableColumn, TableDataSource, TableService} from "../table-objects";
import {Page} from "../../pagination/page";
import {DomSanitizer} from "@angular/platform-browser";
import {TableBase} from "../table-base";


@Component({
    selector: 'lib-table',
    templateUrl: './table.component.html',
    encapsulation: ViewEncapsulation.None
})
export class TableComponent extends TableBase<any> implements OnInit {
    @Input() columns: TableColumn[];
    @Input() data: TableService<any, any>;
    @Input() tableName = '';
    @Output() pageLoad = new EventEmitter<Page<any>>();
    @Input() addPath = 'add';
    @Input() buttonAdd = false;
    @Input() hasPagination = true;
    dataTable: Page<any> = new Page<any>();
    dataSource = new TableDataSource();
    loading = true;

    constructor(protected sanitizer: DomSanitizer) {
        super(sanitizer,[]);
    }

    ngOnInit(): void {
        this.callLoadData();
    }


    override updateRecord() {
        this.callLoadData();
    }

    callLoadData() {
        if (this.data) {
            this.data.lookupTableData(`size=${this.pageSize}&page=${this.pageNumber}`).subscribe(data => {
                this.pageLoadEvent(data);
                this.dataSource.updateData(data.content);
                this.dataTable = data;
                this.pageLoad.next(data);
                this.loading = false;
            });
        }
    }
}
