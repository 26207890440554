import {Injectable} from "@angular/core";
import {debounceTime, Observable, Subject} from "rxjs";
import {FilterData} from "./filter-object";
import {DEFAULT_PAGE_SIZE} from "../pagination/page";
import {isObject} from "../utils";


@Injectable()
export class FilterService {
    private dataEvent: Subject<any> = new Subject();
    private chipsEvent: Subject<any | undefined> = new Subject();
    private toggleEvent: Subject<undefined> = new Subject<undefined>();

    private dataObservable: Observable<any> = this.dataEvent.asObservable().pipe(debounceTime(300));
    private chipsObservable: Observable<any> = this.chipsEvent.asObservable().pipe(debounceTime(200));
    private toggleObservable: Observable<any> = this.toggleEvent.asObservable().pipe(debounceTime(100));


    private filter: any;
    private filters: { [prop: string]: FilterData; } = {};
    private chips: { [prop: string]: FilterData; } = {};
    private pageNumber = 0;
    private pageSize = DEFAULT_PAGE_SIZE;

    get filtersData(): { [prop: string]: FilterData } {
        return this.filters;
    }

    get chipsData(): { [prop: string]: FilterData } {
        return this.chips;
    }

    get page(): { pageSize: number, pageNumber: number } {
        return {pageSize: this.pageSize, pageNumber: this.pageNumber};
    }

    setPage(pageSize: number, pageNumber: number) {
        this.pageSize = pageSize;
        this.pageNumber = pageNumber;
    }

    dataFilterEvent(): Observable<any> {
        return this.dataObservable;
    }

    chipsFilterEvent(): Observable<any> {
        return this.chipsObservable;
    }

    openCloseFilterEvent(): Observable<undefined> {
        return this.toggleObservable;
    }

    prepareFilter(filter: any) {
        if (isObject(filter)) {
            this.filter = {...filter};
            this.chips = {...this.filters};
        } else {
            this.filter = filter;
            this.chips = {};
        }
    }

    retrieveFilter(): any {
        return this.filter;
    }

    updateFilter(filter: any) {
        if (isObject(filter)) {
            this.filter = {... filter};
            this.chips = {...this.filters};
        } else {
            this.filter = filter;
            this.chips = {};
        }
        this.updatePage(this.pageSize, 0);
        this.dispatchEvent();
    }

    removeFilter(filterData: FilterData) {
        if (filterData.defaultValue !== undefined) {
            this.filter[filterData.filterID] = filterData.defaultValue;
        } else {
            if (filterData.filterID.includes('.')) {
                const keys = filterData.filterID.split('.');
                this.deleteNestedProperty(this.filter, keys);
            } else {
                delete this.filter[filterData.filterID];
            }
        }

        delete this.chips[filterData.filterID];
        this.dispatchEvent();
    }

    private deleteNestedProperty(obj: any, keys: string[]) {
        if(keys.length > 1){
            const key = keys.shift();
            if (obj[key]) {
                this.deleteNestedProperty(obj[key], keys);
            }
        }else {
            const lastKey = keys.pop();
            delete obj[lastKey];
        }
    }

    clearFilter() {
        this.filters = {};
        this.filter = {};
        this.chips = {};
        this.dispatchEvent();

    }

    unloadFilter(filterID: string) {
        delete this.chips[filterID];
    }

    toggleFilter() {
        this.toggleEvent.next(null);
    }

    publishFilter(filter: FilterData) {
        this.filters[filter.filterID] = filter;
    }

    updatePage(pageSize: number, pageNumber: number) {
        this.pageSize = pageSize;
        this.pageNumber = pageNumber;
        this.dispatchEvent();
    }

    private dispatchEvent() {
        this.dataEvent.next(this.filter);
        this.chipsEvent.next(null);
    }


}

























