<mat-slide-toggle
        matInput
        [(ngModel)]="model"
        [color]="color"
        [disabled]="disabled"
        (ngModelChange)="onInput($event)"
        (blur)="blurEvent()"
>

</mat-slide-toggle>
<mat-label>{{label}}</mat-label>
