import {Component, EventEmitter, forwardRef, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {CustomValueAccessor} from "../../custom-value-accessor";
import {SelectOptions} from "./select-options";
import {NG_VALUE_ACCESSOR} from "@angular/forms";

@Component({
    selector: 'lib-field-select',
    templateUrl: './field-select.component.html',
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => FieldSelectComponent),
        multi: true
    }]
})
export class FieldSelectComponent extends CustomValueAccessor<SelectOptions> implements OnChanges {

    @Input() blankOption = false;
    @Input() required = false;
    @Input() options: SelectOptions[] = [];

    @Output() eventBlur = new EventEmitter<void>();
    @Output() changeEvent: EventEmitter<SelectOptions> = new EventEmitter();
    private valueId = null;

    @Input() compareWith: (o1: any, o2: any) => boolean = (o1, o2) => o1 == o2;

    onInit(): void {
    }

    onInput($event: any) {
        const value = this.model ? this.model.value : null;
        const text = this.model ? this.model.text : null;
        this.valueId = value;
        this.inputEvent(value, text);
        this.changeEvent.emit(this.model);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['options']?.currentValue) {
            this.model = (this.valueId);
        }
    }

    translateValue(value: any): SelectOptions {
        this.valueId = value;
        return this.options.find(opt => this.compareWith(value, opt.value));

    }

    onblur() {
        this.blurEvent();
        this.eventBlur.emit();
    }

}
