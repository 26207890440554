import {Directive} from '@angular/core';

@Directive({
    selector: '[libConstraint]'
})
export class ConstraintDirective {
    /* @Input() id = 'id';
     @Input() checkConstraints = true;
     @Input() pubConstraints = true;
     @Input() constraints: Constraint[] = [];

     private component: ConstraintComponent;
     private property = '_data';
     private subscribe: Subscription;

     constructor(private view: ViewContainerRef,
                 private _element: ElementRef,
                 private service: ConstraintService,
                 private changeDetector: ChangeDetectorRef) {
     }

     ngOnInit(): void {
         console.log(this._element);
         if (this.view[this.property].componentView.component) {
             if (this.component && this.component.configureConstraints) {
                 this.component
                     .configureConstraints(this.checkConstraints ? this.constraints : [])
                     .pipe(filter(() => this.pubConstraints))
                     .subscribe(
                         state => {
                             this.service.constraintUpdate(this.id, state);
                             this.changeDetector.markForCheck();
                             this.changeDetector.detectChanges();
                         }
                     );
             }
         }
     }

     ngAfterViewInit(): void {
         const state = this.component.isValid();
         if (this.pubConstraints) {
             this.service.constraintUpdate(this.id, state);
         }
         this.changeDetector.markForCheck();
         this.changeDetector.detectChanges();
     }

     ngOnChanges(changes: SimpleChanges): void {
         if (changes['checkConstraints'] && this.component) {
             if (changes['checkConstraints']?.currentValue) {
                 this.component.updateConstraints(this.constraints);
             } else {
                 this.component.configureConstraints([]);
             }

             const state = this.component.isValid();
             if (this.pubConstraints) {
                 this.service.constraintUpdate(this.id, state);
             } else {
                 this.service.constraintUpdate(this.id, true);
             }
         }
     }

     ngOnDestroy(): void {
         this.component = null;
         this.subscribe.unsubscribe();
         this.service.constraintUpdate(this.id, true);
     }
 */
}
