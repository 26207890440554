import {Component, EventEmitter, forwardRef, Input} from '@angular/core';
import {CustomValueAccessor} from "../../custom-value-accessor";
import {NG_VALUE_ACCESSOR} from "@angular/forms";

@Component({
    selector: 'lib-input-toggle',
    templateUrl: './input-toggle.component.html',
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => InputToggleComponent),
        multi: true
    }]
})
export class InputToggleComponent extends CustomValueAccessor<boolean> {
    @Input() appearance: 'fill' | 'legacy' | 'outline' | 'standard' = 'standard';
    @Input() color: 'primary' | 'accent' | 'warn' = 'primary';
    @Input() labelCheck = 'Selected';
    @Input() labelNotCheck = 'Not Selected';
    @Input() changeEvent: EventEmitter<boolean> = new EventEmitter();

    onInit(): void {}

    onInput($event: any) {
        this.inputEvent(this.model, this.model ? this.labelCheck : this.labelNotCheck);
        this.changeEvent.emit(this.model);
    }

    translateValue(value: any): boolean {
        return value;
    }
}
