import {Component} from '@angular/core';
import {FilterService} from "../filter.service";

@Component({
  selector: 'lib-filter-button',
  templateUrl: './filter-button.component.html'
})
export class FilterButtonComponent {

  constructor(private filterService: FilterService) { }

  onClick() {
    this.filterService.toggleFilter();
  }


}
