import { Injectable } from '@angular/core';
import { Configs } from './general-objects';

@Injectable()
export class ConfigService {
  constructor(private propMetadata: Configs) {
  }

  public get config(): Configs {
    return this.propMetadata;
  }
}
