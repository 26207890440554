<div class="form-group">
    <label>{{label}}<span *ngIf="required" class="star">*</span></label>
    <select class="form-control"
            [(ngModel)]="model"
            [disabled]="disabled"
            [required]="required"
            (ngModelChange)="onInput($event)"
            (blur)="onblur()"
    >
        <option *ngIf="blankOption" [ngValue]="undefined" [defaultSelected]="true">Select</option>
        <option *ngFor="let option of options" [ngValue]="option">{{option.text}}</option>
    </select>
</div>
