import {Directive, ElementRef, OnDestroy} from '@angular/core';
import * as textMask from 'vanilla-text-mask/dist/vanillaTextMask.js';
import {MaskTypes} from "../input-mask/input-mask-types";

@Directive({
    selector: '[libMaskDate]'
})
export class MaskDateDirective implements OnDestroy {

    private maskedInputController: any;

    constructor(private element: ElementRef) {
        this.maskedInputController = textMask.maskInput({
            inputElement: this.element.nativeElement,
            mask: MaskTypes.DATE,
            placeholderChar: '\u2000'
        })
    }

    ngOnDestroy(): void {
        this.maskedInputController.destroy();
    }

}
