import {NgModule} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AppComponent} from './app.component';
import {SidebarModule} from './views/sidebar/sidebar.module';

import {FooterModule} from './shared/footer/footer.module';
import {NavbarModule} from './shared/navbar/navbar.module';
import {PagesnavbarModule} from './shared/pagesnavbar/pagesnavbar.module';
import {AdminLayoutComponent} from './views/layouts/admin/admin-layout.component';
import {AuthLayoutComponent} from './views/layouts/auth/auth-layout.component';
import {AppRoutingModule} from './app-routing.module';
import {ConfigService} from "./views/security/config.service";
import {environment} from "../environments/environment";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {AuthInterceptorService} from "./views/security/auth-interceptor.service";
import {LoginModule} from "./views/login/login.module";
import {UnauthorizedComponent} from "./views/security/unauthorized/unauthorized.component";
import {NotfoundComponent} from './views/security/notfound/notfound.component';
import {HttpInterceptorError} from "./views/security/http-interceptor-error";
import {SpinnerComponent} from './components/lib/spinner/spinner.component';
import {SpinnerInterceptor} from "./components/lib/spinner/spinner.interceptor";
import {DatePipe, HashLocationStrategy, LocationStrategy} from "@angular/common";


@NgModule({
    imports: [
        BrowserAnimationsModule,
        FormsModule,
        AppRoutingModule,
        SidebarModule,
        NavbarModule,
        FooterModule,
        PagesnavbarModule,
        HttpClientModule,
        LoginModule,
        ReactiveFormsModule
    ],
    declarations: [
        AppComponent,
        AdminLayoutComponent,
        AuthLayoutComponent,
        UnauthorizedComponent,
        NotfoundComponent,
        SpinnerComponent

    ],
    providers: [
        {provide: LocationStrategy, useClass: HashLocationStrategy},
        {
            provide: ConfigService,
            useFactory: initializeConfigService
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpInterceptorError,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptorService,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: SpinnerInterceptor,
            multi: true
        }, DatePipe
    ],
    exports: [],
    bootstrap: [AppComponent]
})

export class AppModule {
}

export function initializeConfigService() {
    return new ConfigService(environment);
}
