import {DEFAULT_PAGE_SIZE, DEFAULT_PAGE_SIZE_OPTIONS, Page} from "../pagination/page";
import {TableColumn} from "./table-objects";
import {DomSanitizer, SafeStyle} from "@angular/platform-browser";

export abstract class TableBase<T> {
    protected headers: string[] = [];
    protected pageSize = DEFAULT_PAGE_SIZE;
    protected pageNumber = 0;
    protected totalPages = 0;
    protected pages: number[] = [];
    protected totalSizeOfPages = DEFAULT_PAGE_SIZE_OPTIONS;
    protected disableFirst = true;
    protected disableLast = false;
    protected recordsPage: Page<T> = new Page<T>();

    protected constructor(protected sanitizer: DomSanitizer ,headers?: string[]) {
        this.headers = headers;
    }

    protected pageLoadEvent(page: Page<T>): void {
        this.recordsPage = page;
        this.updatePages();
    }

    protected updatePages(): void {
        const pageNumber = this.recordsPage.number;
        this.disableFirst = this.recordsPage.first;
        this.disableLast = this.recordsPage.last;
        this.totalPages = this.recordsPage.totalPages;
        this.pages = [pageNumber];
        let initialPage = pageNumber -1;
        let endPage = pageNumber + 1;

        while (this.pages.length < 5) {
            if(initialPage > -1){
                this.pages = [initialPage --, ... this.pages];
            }

            if(endPage < this.totalPages){
                this.pages = [... this.pages, endPage++];
            }

            if (initialPage < 0 && endPage >= this.totalPages) {
                break;
            }
        }
    }

    protected isEmpty(): boolean {
        return this.recordsPage == undefined || false;
    }

    protected abstract updateRecord(parameters?: string | {page: number, size: number});

    protected gotoPage(page: number) {
        this.pageNumber = page;
        this.updateRecord(`size=${this.pageSize}&page=${this.pageNumber}`);
    }

    protected previousPage() {
        if ((this.pageNumber - 1) >= 0) {
            this.pageNumber--;
            this.updateRecord(`size=${this.pageSize}&page=${this.pageNumber}`);
        }
    }

    protected nextPage() {
        if ((this.pageNumber + 1) < this.totalPages) {
            this.pageNumber++;
            this.updateRecord('size' + "=" + this.pageSize + '&page=' + "" + this.pageNumber);
        }
    }

    protected changePageSize($event: Event) {
        this.pageSize = $event.target['value'] as number;
        this.pageNumber = 0;

        this.updateRecord('size' + "=" + this.pageSize + '&page=' + "" + this.pageNumber);
    }

    protected gotoLastPage() {
        if ((this.pageNumber + 1) < this.totalPages) {
            this.gotoPage(this.totalPages - 1);
        }
    }

    protected gotoFirstPage() {
        if ((this.pageNumber - 1) >= 0) {
            this.gotoPage(0);
        }
    }

    protected getHeadersStyle(column: TableColumn): SafeStyle {
        return this.sanitizer.bypassSecurityTrustStyle(column.headerStyle);
    }

   protected getBodyStyle(style: string): SafeStyle {
        return this.sanitizer.bypassSecurityTrustStyle(style);
    }

    protected getBodyClass(css: string): string {
        return css || '';
    }

    protected getHeaderClass(column: TableColumn): string {
        if (column.headerClass) {
            return column.headerClass + ' ' + column.styleSort;
        }
        return column.styleSort;
    }


}
