import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TableComponent} from './table/simple/table.component';
import {InputTextComponent} from './inputs/input-text/input-text.component';
import {FormsModule} from "@angular/forms";
import {FieldSelectComponent} from './inputs/field-select/field-select.component';
import {ConstraintDirective} from './inputs/constraint.directive';
import {ConstraintCheckDirective} from './inputs/constraint-check.directive';
import {ButtonComponent} from './button/button.component';
import {InputCheckboxComponent} from './inputs/input-checkbox/input-checkbox.component';
import {TableBodyComponent} from './table/table-body/table-body.component';
import {RouterModule} from "@angular/router";
import {ModalComponent} from './modal/modal.component';
import {A11yModule} from "@angular/cdk/a11y";
import {MatDialogModule} from "@angular/material/dialog";
import {FilterAreaComponent} from './filter/filter-area/filter-area.component';
import {MatCardModule} from "@angular/material/card";
import {FilterButtonComponent} from './filter/filter-button/filter-button.component';
import {FilterChipsComponent} from './filter/filter-chips/filter-chips.component';
import {MatChipsModule} from "@angular/material/chips";
import {MatIconModule} from "@angular/material/icon";
import {FilterTitleComponent} from './filter/filter-title/filter-title.component';
import {MatDividerModule} from "@angular/material/divider";
import {FilterViewComponent} from './filter/filter-view/filter-view.component';
import {FilterDirective} from "./filter/filter.directive";
import {TableFilterComponent} from './table/table-filter/table-filter.component';
import {OrderByPipe} from "./pipe/orderby.pipe";
import {InputToggleComponent} from './inputs/input-toggle/input-toggle.component';
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {MatInputModule} from "@angular/material/input";
import {DatePickerComponent} from './inputs/date-picker/date-picker.component';
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MaskDateDirective} from './inputs/date-picker/mask-date.directive';


@NgModule({
    declarations: [
        TableComponent,
        InputTextComponent,
        FieldSelectComponent,
        ConstraintDirective,
        ConstraintCheckDirective,
        FilterDirective,
        ButtonComponent,
        InputCheckboxComponent,
        TableBodyComponent,
        ModalComponent,
        FilterAreaComponent,
        FilterButtonComponent,
        FilterChipsComponent,
        FilterTitleComponent,
        FilterViewComponent,
        TableFilterComponent,
        OrderByPipe,
        InputToggleComponent,
        DatePickerComponent,
        MaskDateDirective
    ],
    exports: [
        InputTextComponent,
        FieldSelectComponent,
        ConstraintDirective,
        ButtonComponent,
        InputCheckboxComponent,
        TableComponent,
        ModalComponent,
        FilterViewComponent,
        FilterDirective,
        OrderByPipe,
        InputToggleComponent,
        DatePickerComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        A11yModule,
        MatDialogModule,
        MatCardModule,
        MatChipsModule,
        MatIconModule,
        MatDividerModule,
        MatSlideToggleModule,
        MatInputModule,
        MatDatepickerModule
    ]
})
export class ComponentModule {
}
