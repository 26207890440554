import {Injectable} from '@angular/core';
import {BaseCrudService} from "../../components/lib/http-client/crud-http-client.service";
import {HttpClient} from "@angular/common/http";
import {ConfigService} from "../security/config.service";
import {TableFilterService} from "../../components/lib/table/table-objects";
import {Page, PageFilter} from "../../components/lib/pagination/page";
import {Observable} from "rxjs";
import {AgentLog} from "./agent-log.object";
import {map} from 'rxjs/operators';
import {DatePipe} from "@angular/common";
import {DATE_TIME_FORMAT} from "../../components/lib/utils/dates";

@Injectable({
    providedIn: 'root'
})
export class AgentLogService extends BaseCrudService<number, AgentLog, AgentLog> implements TableFilterService<AgentLog, AgentLog> {

    constructor(override http: HttpClient, private configService: ConfigService, private datePipe: DatePipe) {
        super('/v1/agent/log', http);
    }

    protected getBackendUrl(): string {
        return this.configService.config['uri'];
    }

    lookupTableData(filter?: PageFilter<any>): Observable<Page<any>> {
        return this.filter(filter).pipe(
            map((page: Page<AgentLog>) => {
            page.content = page.content.map(agentLog => ({
                ...agentLog, datetime: this.datePipe.transform(agentLog.datetime, DATE_TIME_FORMAT)
            }));
            return page;
        }));
    }
}
