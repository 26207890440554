import {Injectable} from "@angular/core";
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {catchError, Observable, throwError} from "rxjs";
import {NotificationService} from "../../components/lib/notifications/notification.service";

@Injectable()
export class HttpInterceptorError implements HttpInterceptor {
    constructor(private notification: NotificationService) {
        console.log('Error Interceptor Initializate');
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            catchError((error: HttpErrorResponse) => {
                if (error.status === 401) {
                    // refresh token
                } else {
                    return this.showError(error);
                }
            })
        );
    }

    private showError(error: any) {
        if (error instanceof HttpErrorResponse && (error as HttpErrorResponse).status > 399) {
            this.notification.error(error.error.message);
        }
        return throwError(() => error);
    }
}
