export const DEFAULT_PAGE_SIZE = 10;
export const DEFAULT_PAGE_SIZE_OPTIONS = [10, 25, 100, 200];


export class PageFilter<F> {
    filter: F;
    page?: {
        sort?: {
            order?: 'asc' | 'desc' | '';
            field?: string;
        }
        pageNumber?: number;
        pageSize?: number;
    } = {
        sort: {order: 'asc', field: 'id'},
        pageNumber: 0,
        pageSize: DEFAULT_PAGE_SIZE
    }
}

export class Sort {
    sorted: boolean;
    unsorted: boolean;
    empty: boolean
}

export class Pageable {
    offset: number;
    pageNumber: number;
    pageSize: number;
    paged: boolean;
    unpaged: boolean;
}

export class Page<T> {
    content: T[];
    pageable: Pageable;
    last = true;
    totalElements = 0;
    totalPages = 0;
    size = 0;
    number = 0;
    sort: Sort = {sorted: false, unsorted: true, empty: true};
    first = true;
    numberOfElements = 0;
    empty = true;
}
