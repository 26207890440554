import {Component, OnInit} from '@angular/core';
import {User} from "./login.object";
import {AuthService} from "../security/auth.service";
import {Router} from "@angular/router";
import {NotificationService} from "../../components/lib/notifications/notification.service";
import {BaseView} from "../../components/lib/base.view";


let $: any;

@Component({
    moduleId: module.id,
    selector: 'login-cmp',
    templateUrl: './login.component.html'
})

export class LoginComponent extends BaseView implements OnInit {
    test: Date = new Date();
    user: User = new User("", "");

    constructor(private authService: AuthService, private router: Router, private notification: NotificationService) {
        super();
    }

    checkFullPageBackgroundImage() {
        const $page = $('.full-page');
        const image_src = $page.data('image');

        if (image_src !== undefined) {
            const image_container = '<div class="full-page-background" style="background-image: url(' + image_src + ') "/>';
            $page.append(image_container);
        }
    }

    ngOnInit() {
        this.checkFullPageBackgroundImage();
    }

    onSubmit() {
        this.authService.login(this.user.userId, this.user.password)
            .subscribe({
                    next: () => {
                        this.router.navigateByUrl("dashboard");
                    },
                    error: (erro) => {
                        this.user = new User("", "");
                        console.log(erro)
                        this.notification.warning('Username or password is invalid, please try again');
                    }
                }
            );
    }
}
