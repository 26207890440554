export class MaskTypes {
    public static readonly DATE = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
}

export enum MaskTypesEnum {
    TEXT,
    INTEGER_NUMBER,
    FLOAT_NUMBER,
    FINANCY_NUMBER,
    DATE
}

export class NumericMask {
    prefix: string;
    suffix: string;
    includeThousandsSeparator: boolean;
    thousandsSeparatorSymbol: string;
    allowDecimal: boolean;
    decimalLimit: number;
    integerLimit: number;
    requireDecimal: boolean;
    allowNegative: boolean;
    allowLeadingZeroes: boolean;
}


export const INTEGER_MASK: NumericMask = {
    prefix: '',
    suffix: '',
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: ',',
    allowDecimal: false,
    decimalLimit: 0,
    integerLimit: 50,
    requireDecimal: false,
    allowNegative: true,
    allowLeadingZeroes: false
};


export const FLOAT_MASK: NumericMask = {
    prefix: '',
    suffix: '',
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: ',',
    allowDecimal: true,
    decimalLimit: 10,
    integerLimit: 50,
    requireDecimal: false,
    allowNegative: true,
    allowLeadingZeroes: false
};





