import {Injectable} from "@angular/core";
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {AccessToken, Principal, UserToken} from "./auth-objects";
import {BehaviorSubject, map, Observable, of} from "rxjs";
import {Data, Router} from "@angular/router";
import * as moment from "moment";
import {ConfigService} from "./config.service";

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private options: {
        headers?: HttpHeaders;
        observe?: 'body';
        params?: HttpParams;
        reportProgress?: boolean;
        responseType?: any;
        withCredentials?: boolean;
    } = {};

    private ACCESS_TOKEN_KEY = 'request_config';

    private USER_TOKEN_KEY = '_s';

    private REFRESH_AT_KEY = 'next_request';

    private accessToken: AccessToken;
    private userDetail: Principal;

    private userSubject: BehaviorSubject<Principal> = new BehaviorSubject<Principal>(undefined);

    private userDetailEvent: Observable<Principal> = this.userSubject.asObservable();

    private refreshTimer: any = null;

    constructor(
        private http: HttpClient,
        private loadConfig: ConfigService,
        private router: Router
    ) {
        console.log('Security Service initialize');
        this.options.headers = new HttpHeaders()
            .set('no-error', 'true')
            .set('Content-Type', 'application/json')
        this.options.responseType = 'json';
        this.retrieve();
    }

    public onUserDetail(): Observable<Principal> {
        return this.userDetailEvent;
    }

    public onReadyState(): Observable<boolean> {
        return of(this.accessToken != undefined);
    }

    private retrieve() {
        const now = moment();
        const expireIn = sessionStorage.getItem(this.REFRESH_AT_KEY);
        const userToken = sessionStorage.getItem(this.USER_TOKEN_KEY);

        if (!userToken || !expireIn) {
            return;
        }
        const expireMoment = moment(Number.parseInt(expireIn, 10));
        if (!expireMoment.isAfter(now)) {
            return;
        }

        this.accessToken = JSON.parse(userToken).token;
        this.userDetail = JSON.parse(userToken).identity;
        this.refreshTimer = setTimeout(() => this.refreshToken(), expireMoment.diff(now, 'milliseconds'))
        this.userSubject.next(this.userDetail);

    }

    public login(username: string, password: string): Observable<boolean> {
        return this.http.post<UserToken>(
            this.loadConfig.config['uri'] + this.loadConfig.config['login_url'],
            {
                userId: username,
                password: password
            },
            this.options
        ).pipe(
            map(userToken => this.cacheAuthentication(userToken))
        );
    }

    private refreshToken() {



        this.http.post<UserToken>(
            `${this.loadConfig.config["uri"]}${this.loadConfig.config['refresh_url']}`,
            {
              "refreshToken" : this.accessToken.refresh_token
            },
            this.options
        ).subscribe({
                next: userToken => this.cacheAuthentication(userToken),
                error: () => this.cleanAuthentication()
            }
        )
    }

    private cacheAuthentication(token: UserToken): boolean {
        this.userDetail = token.identity;
        this.accessToken = token.token;
        const now = moment();
        const timeout = this.calculateTimeout(this.accessToken.expires_in);
        const expiredDate = now.add(timeout, 'seconds');
        sessionStorage.setItem(this.REFRESH_AT_KEY, '' + expiredDate.valueOf());
        sessionStorage.setItem(this.USER_TOKEN_KEY, JSON.stringify(token));
        sessionStorage.setItem(this.ACCESS_TOKEN_KEY, this.accessToken.access_token);

       this.refreshAuthentication();
        this.userSubject.next(this.userDetail);
        return true;
    }

    private refreshAuthentication() {
        this.clearRefreshTimer();
        const timout = this.calculateTimeout(this.accessToken.expires_in);
        this.refreshTimer = setTimeout(() => this.refreshToken(), timout * 1000);
    }

    private cleanAuthentication() {
        this.clearRefreshTimer();
        this.accessToken = undefined;
        this.userDetail = undefined;
        sessionStorage.clear();
        this.router.navigateByUrl('/login');
    }

    private calculateTimeout(timeout: number): number {
        timeout = timeout - 60;
        if (timeout > 0) {
            return timeout;
        }
        return 0;
    }

    public logout() {
        const urlEncoded = new HttpParams().append('token', this.accessToken.access_token);
        this.http.post<void>(
            `${this.loadConfig.config["uri"]}${this.loadConfig.config['logout_url']}`,
            urlEncoded.toString(),
            this.options
        ).subscribe(
            {
                next: () => this.cleanAuthentication(),
                error: () => this.cleanAuthentication()
            }
        )
    }

    public validateAccess(roles: Data): boolean {
        const authority = roles.authority;
        if (authority === 'none') {
            return true;
        }
        if (!authority) {
            return false;
        }

        if (!this.userDetail || !this.userDetail.authorities) {
            return false;
        }

        return this.userDetail.authorities.find(auth => auth.authority === authority) !== undefined;
    }

    private clearRefreshTimer() {
        if (this.refreshTimer) {
            clearTimeout(this.refreshTimer)
        }
    }

    public isAuthenticated(): boolean {
        return this.accessToken != undefined;
    }

    get isReady(): boolean {
        return this.userDetail !== undefined;
    }

    get userID(): number {
        return this.userDetail && this.userDetail.id;
    }

    get userInfo(): Principal {
        return this.userDetail || {};
    }

    get accessDetail(): AccessToken {
        return this.accessToken;
    }
}
