import {ControlValueAccessor, NgModel} from "@angular/forms";
import {Component, Input, OnInit, ViewChild} from "@angular/core";
import {Observable, Subject} from "rxjs";
import {FilterData} from "./filter/filter-object";
import {Constraint} from "./inputs/input-objects";
import {VOID} from "./utils";

@Component({template: ''})
export abstract class CustomValueAccessor<Model> implements ControlValueAccessor, OnInit {

    @Input() label: string;
    @Input() disabled: boolean;

    @ViewChild(NgModel, {static: false}) ngModel: NgModel;

    public model: Model;
    public constraintMessage = '';

    private filterEvent: Subject<FilterData> = new Subject();
    private constraintEvent: Subject<boolean> = new Subject();
    private constraints: Constraint[];

    private onTouchedCallback: () => void = VOID;
    private onChangeCallback: (_: any) => void = VOID;

    get value(): any {
        return this.model;
    }

    set value(v: any) {
        if (v !== this.model) {
            this.writeValue(v);
            this.onChangeCallback(v);
        }
    }

    abstract translateValue(value: any): Model;

    ngOnInit(): void {
        this.onInit();
    }

    abstract onInit(): void;

    registerOnChange(fn: any): void {
        this.onChangeCallback = fn;
    }

    writeValue(obj: any): void {
        const translated = this.translateValue(obj);
        if (this.model !== translated) {
            this.model = translated;
        }
        if (this.constraints) {
            this.constraintEvent.next(this.isValid());
        }
    }

    registerOnTouched(fn: any): void {
        this.onTouchedCallback = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    inputEvent(value: any, displayValue?: string) {
        const display = typeof displayValue === 'string' ? displayValue : value;
        this.onChangeCallback(value);
        this.filterEvent.next({
            label: this.label,
            value: display
        });
        if (this.constraints) {
            this.constraintEvent.next(this.isValid());
        }
    }

    blurEvent() {
        if (this.constraints) {
            this.constraintEvent.next(this.isValid());
        }
        this.onTouchedCallback();
    }

    filterChange(): Observable<FilterData> {
        return this.filterEvent.asObservable();
    }

    updateConstraints(constraints: Constraint[]) {
        this.constraints = constraints;
        this.ngModel.control.setErrors(null);
        this.constraintMessage = '';
    }

    configureConstraints(constraints: Constraint[]): Observable<boolean> {
        this.constraints = constraints;
        return this.constraintEvent.asObservable();
    }

    isValid(): boolean {
        for (const constraint of this.constraints) {
            if (!constraint.apply(this.model)) {
                this.constraintMessage = constraint.message;
                this.ngModel.control.markAllAsTouched();
                this.ngModel.control.markAsDirty();
                this.ngModel.control.setErrors({invalid: true});
                return false;
            }
        }
        this.ngModel.control.setErrors(null);
        this.constraintMessage = '';
        return true;
    }


}
