import {RouterModule, Routes} from '@angular/router';
import {AdminLayoutComponent} from './views/layouts/admin/admin-layout.component';
import {AuthLayoutComponent} from './views/layouts/auth/auth-layout.component';
import {AuthGuard} from "./views/security/auth-guard";
import {NgModule} from "@angular/core";
import {UnauthorizedComponent} from "./views/security/unauthorized/unauthorized.component";
import {NotfoundComponent} from "./views/security/notfound/notfound.component";
import {FileTypeComponent} from "./views/fileType/file-type.component";
import {AgentComponent} from "./views/agent/agent.component";
import {DestinationComponent} from "./views/destination/destination.component";

import {MultiStepFormAgentComponent} from "./views/multi-step-form-agent/multi-step-form-agent.component";
import {AgentLogComponent} from "./views/agent-log/agent-log.component";


export const AppRoutes: Routes = [
    {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full'
    },
    {
        path: '401',
        component: UnauthorizedComponent
    },
    {
        path: '404',
        component: NotfoundComponent
    },
    {
        path: '',
        component: AdminLayoutComponent,
        canActivate: [AuthGuard],
        data: {authority: 'none'},
        children: [
            {
                path: '',
                canActivate: [AuthGuard],
                data: {authority: 'USER'},
                loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule)
            },
            {
                path: '',
                canActivate: [AuthGuard],
                data: {authority: 'USER'},
                loadChildren: () => import('./alerts/alerts.module').then(m => m.AlertsModule)
            },
            {
                path: '',
                canActivate: [AuthGuard],
                data: {authority: 'USER'},
                loadChildren: () => import('./views/fileLog/file-log.module').then(m => m.FileLogModule)
            },
            {
                path: 'file-type',
                component: FileTypeComponent,
                canActivate: [AuthGuard],
                data: {authority: 'USER'},
                loadChildren: () => import('./views/fileType/file-type.module').then(m => m.FileTypeModule)
            },
            {
                path: 'agent',
                component: AgentComponent,
                canActivate: [AuthGuard],
                data: {authority: 'USER'},
                loadChildren: () => import('./views/agent/agent.module').then(m => m.AgentModule)
            },
            {
                path: 'site',
                component: DestinationComponent,
                canActivate: [AuthGuard],
                data: {authority: 'USER'},
                loadChildren: () => import('./views/destination/destination.module').then(m => m.DestinationModule)
            },
            {
                path: 'multi-step-form-agent',
                component: MultiStepFormAgentComponent,
                canActivate: [AuthGuard],
                data: {authority: 'USER'},
                loadChildren: () => import('./views/multi-step-form-agent/multi-step-form-agent.module').then(m => m.MultiStepFormAgentModule)
            },
            {
                path: 'agent-log',
                component: AgentLogComponent,
                canActivate: [AuthGuard],
                data: {authority: 'USER'},
                loadChildren: () => import('./views/agent-log/agent-log.module').then(m => m.AgentLogModule)
            }
        ]
    }, {
        path: '',
        component: AuthLayoutComponent,
        children: [
            {
                path: 'login',
                loadChildren: () => import('./views/login/login.module').then(m => m.LoginModule)
            }
        ]
    },

    {
        path: '**',
        redirectTo: '404'
    }
];

@NgModule({
    imports: [RouterModule.forRoot(AppRoutes)],
    providers: [AuthGuard],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
