import * as moment from "moment";
import {Moment} from "moment";


export const DATE_TIME_FORMAT = 'yyyy-MM-dd HH:mm:ss';
export function convertMomentToDateTimeFormat(date: Moment): any {
    const validateDateString = new RegExp('^[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}.[0-9]{1,3}$')
    if (date && validateDateString.test(date.toString())){
        return moment(date).format('MM-DD-YYYY HH:mm:ss');
    }
    return date;
}