<div class="checkbox">

    <input
            [(ngModel)]="model"
            [disabled]="disabled"
            (ngModelChange)="onInput($event)"
            (blur)="blurEvent()"
            [id]="idLabelFor" name="test"
            type="checkbox">
    <label [for]="idLabelFor">{{label}}</label>


</div>
