export class Strings {
    public static readonly EMPTY = '';

    public static camelCaseWithSpace(field: string) {
        return field.replace(/([A-Z])/g, ' $1').trim();
    }

    public static isString(field: any): boolean {
        return typeof field === 'string';
    }


}
