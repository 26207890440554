import {Component, ViewChild} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {Location} from '@angular/common';
import {PagesnavbarComponent} from '../../../shared/pagesnavbar/pagesnavbar.component';
import {filter, Subscription} from 'rxjs';

@Component({
    selector: 'app-layout',
    templateUrl: './auth-layout.component.html'
})

export class AuthLayoutComponent {
    location: Location;
    @ViewChild(PagesnavbarComponent) pagesnavbar: PagesnavbarComponent;
    private _router: Subscription;

    constructor(private router: Router, location: Location) {
        this.location = location;
    }

    ngOnInit() {
        this._router = this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
            this.pagesnavbar.sidebarClose();
        });
    }
}
