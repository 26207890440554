import {Constraint} from "./inputs/input-objects";
import {Strings} from "./utils/strings";
import {Page, PageFilter} from "./pagination/page";
import {Observable} from "rxjs";
import {TableFilterService, TableService} from "./table/table-objects";

export abstract class BaseView {

    createLookupTableData<F, R>(
        lookupTableData: (queryString?: string) => Observable<Page<R>>
    ): TableService<F, R> {
        return {lookupTableData};
    }


    createLookupTableFilterData<F,R>(
        lookupTableData: (pageWithFilter: PageFilter<F>) => Observable<Page<R>>
    ): TableFilterService<F, R> {
        return { lookupTableData };
    }
    protected backHistory() {
        window.history.back();
    }

    protected isNullOrUndefined(value: any) {
        return value === undefined || value === null;
    }

    protected requiredConstraint(message: string = 'Required field'): Constraint {
        return {
            type: "required",
            message,
            apply: (value) => !this.isEmpty(value)
        }
    }


    protected isEmpty(value: string) {
        return this.isNullOrUndefined(value) || (Strings.isString(value) && value.trim().length === 0);
    }
}
