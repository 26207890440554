<div class="content">
    <div class="row px-3">
        <div style="line-height: 2.5em;">
            <span class="page-title" *ngFor="let title of titles">{{title}}</span>
        </div>
        <div class="mb-1" style="flex-grow: 1;">
            <ng-content></ng-content>
            <div class="clear"></div>
        </div>
    </div>
    <mat-divider class="mb-3"></mat-divider>
</div>
