

    <div class="card col-12">

        <div class="content table-responsive">
            <div class="row col-md-3" *ngIf="hasPagination">
                <div class="form-inline mx-2 my-3">
                    <div class="form-group">
                        <label class="mx-2 my-3">Show</label>
                        <select (change)="changePageSize($event)" class="form-control">
                            <option *ngFor="let totalPage of totalSizeOfPages"
                                    value="{{totalPage}}">{{ totalPage }}
                            </option>
                        </select>
                        <label class="mx-2 my-3">Entries</label>
                    </div>
                </div>
            </div>
            <button class="btn btn-primary float-end" [routerLink]="addPath" *ngIf="buttonAdd">Add</button>
            <table class="table table-hover table-striped">
                <thead>
                <tr>
                    <th *ngFor="let column of columns"
                        [class]="getHeaderClass(column)"
                        [style]="getHeadersStyle(column)"
                        (click)="sortable(column)"
                    >
                        <div class="filtertable__th">
                            <span>{{ column.header }}</span>
                            <i [class]="selectIcon(column)"></i>
                        </div>

                    </th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let row of dataSource.connect() | async">
                    <td *ngFor="let column of columns"
                        [class]="getBodyClass(column.styleClass(row))"
                        [style]="getBodyStyle(column.styleCss(row))"
                        (click)="column.event(row)"
                    >
                        <lib-table-body [column]="column" [row]="row"></lib-table-body>

                    </td>
                </tr>
                </tbody>
            </table>
            <nav aria-label="Page navigation" *ngIf="hasPagination">
                <ul class="pagination">
                    <li (click)="gotoFirstPage()" [class.disabled]="disableFirst" class="page-item">
                        <a class="page-link"><<</a>
                    </li>
                    <li (click)="previousPage()" [class.disabled]="disableFirst" class="page-item">
                        <a class="page-link"><</a>
                    </li>
                    <li (click)="gotoPage(page)" *ngFor="let page of pages" [class.active]="page === pageNumber"
                        class="page-item">
                        <a class="page-link">{{ page + 1 }}</a>
                    </li>
                    <li (click)="nextPage()" [class.disabled]="disableLast" class="page-item">
                        <a class="page-link">></a>
                    </li>
                    <li (click)="gotoLastPage()" [class.disabled]="disableLast" class="page-item">
                        <a class="page-link">>></a>
                    </li>
                </ul>
            </nav>
        </div>
    </div>


